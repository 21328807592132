import React from 'react';
import PropTypes from 'prop-types';

import { INSTAGRAM_SOCIAL_MEDIA, LINKEDIN_SOCIAL_MEDIA } from '../constants';
import NavBar from '../NavBar/NavBar';
import LanguageSection from '../LanguageSection/LanguageSection';

const NavBarFullScreen = ({ scrollToSection, language, setLanguage, setToggleMenu }) => {
  const handleScrollToSection = (section) => {
    setToggleMenu(false);
    scrollToSection(section);
  };

  return (
    <div id="navbar-full-screen">
      <div className="navbar-full-screen-itens">
        <NavBar scrollToSection={handleScrollToSection} />
      </div>
      <div className="navbar-full-screen-buttons">
        <div className="social-media-buttons">
          <button
            type="button"
            aria-label="Go to Instagram!"
            className="logo instagram-logo"
            onClick={() => window.open(INSTAGRAM_SOCIAL_MEDIA, '_blank')}
          />
          <button
            type="button"
            aria-label="Go to LinkedIn!"
            className="logo linkedin-logo"
            onClick={() => window.open(LINKEDIN_SOCIAL_MEDIA, '_blank')}
          />
        </div>
        <LanguageSection language={language} setLanguage={setLanguage} />
      </div>
    </div>
  );
};

NavBarFullScreen.propTypes = {
  scrollToSection: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  setToggleMenu: PropTypes.func.isRequired,
};

export default NavBarFullScreen;
