import { defineMessages } from 'react-intl';

export default defineMessages({
  darkCrownDescription: {
    id: 'app.components.darkCrownSection.darkCrownDescription',
    defaultMessage:
      'Um roguelike deckbuilder onde um novo tipo de xadrez encontra habilidades mágicas.',
  },
  buttonVideo: {
    id: 'app.components.darkCrownSection.buttonVideo',
    defaultMessage: 'Vídeo',
  },
  expandText: {
    id: 'app.components.darkCrownSection.expandText',
    defaultMessage: 'Expandir',
  },
});
