import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { SECTIONS } from '../constants';

import messages from './messages';

const NavBar = ({ scrollToSection }) => {
  const intl = useIntl();

  return (
    <nav className="nav">
      <button type="button" className="nav-item" onClick={() => scrollToSection(SECTIONS.emphasis)}>
        {intl.formatMessage(messages.navItemEmphasis)}
      </button>
      <button type="button" className="nav-item" onClick={() => scrollToSection(SECTIONS.projects)}>
        {intl.formatMessage(messages.navItemProjects)}
      </button>
      <button type="button" className="nav-item" onClick={() => scrollToSection(SECTIONS.essence)}>
        {intl.formatMessage(messages.navItemEssence)}
      </button>
      <button type="button" className="nav-item" onClick={() => scrollToSection(SECTIONS.contact)}>
        {intl.formatMessage(messages.navItemContact)}
      </button>
    </nav>
  );
};

NavBar.propTypes = {
  scrollToSection: PropTypes.func.isRequired,
};

export default NavBar;
