import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import NavBar from '../NavBar/NavBar';
import LanguageSection from '../LanguageSection/LanguageSection';
import messages from './messages';
import ImageLogo from '../../../assets/img/hyperdive-logo.svg';
import { INSTAGRAM_SOCIAL_MEDIA, LINKEDIN_SOCIAL_MEDIA } from '../constants';

const AppBar = ({ language, setLanguage, scrollToSection, toggleMenu, setToggleMenu }) => {
  const intl = useIntl();
  const handleMenuClick = () => setToggleMenu((state) => !state);

  return (
    <>
      <header id="navbar" {...(toggleMenu ? { className: 'fixed-navbar' } : {})}>
        <div className="menu">
          <div className="navbar-logo">
            <img
              src={ImageLogo}
              className="img-logo"
              alt={intl.formatMessage(messages.navBarLogo)}
            />
          </div>
          <nav className="nav">
            <NavBar scrollToSection={scrollToSection} />
            <button
              type="button"
              aria-label="Go to Instagram!"
              className="logo instagram-logo"
              onClick={() => window.open(INSTAGRAM_SOCIAL_MEDIA, '_blank')}
            />
            <button
              type="button"
              aria-label="Go to LinkedIn!"
              className="logo linkedin-logo"
              onClick={() => window.open(LINKEDIN_SOCIAL_MEDIA, '_blank')}
            />
          </nav>
          <button
            type="button"
            className={toggleMenu ? 'menu-open-close-button active' : 'menu-open-close-button'}
            onClick={handleMenuClick}
          >
            {toggleMenu
              ? intl.formatMessage(messages.closeMenu)
              : intl.formatMessage(messages.openMenu)}
          </button>
        </div>
        <div className="language-buttons">
          <LanguageSection language={language} setLanguage={setLanguage} />
        </div>
      </header>
      <div className="hiring-advertisement">{intl.formatMessage(messages.hiringAdvertisement)}</div>
    </>
  );
};

AppBar.propTypes = {
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  scrollToSection: PropTypes.func.isRequired,
  toggleMenu: PropTypes.bool.isRequired,
  setToggleMenu: PropTypes.func.isRequired,
};

export default AppBar;
