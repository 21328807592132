import { defineMessages } from 'react-intl';

export default defineMessages({
  gamesSectionTitle: {
    id: 'app.components.GamesSection.gamesSectionTitle',
    defaultMessage: 'Projetos',
  },
  gamesSectionDescription: {
    id: 'app.components.GamesSection.gamesSectionDescription',
    defaultMessage:
      'Desenvolvemos uma variedade de experiências, desafios e aventuras! Descubra alguns dos nossos projetos no link abaixo.',
  },
  gamesSectionSeeMore: {
    id: 'app.components.GamesSection.gamesSectionSeeMore',
    defaultMessage: 'Veja mais',
  },
  gamesSectionExpand: {
    id: 'app.components.GamesSection.gamesSectionExpand',
    defaultMessage: 'Expandir',
  },
  gamesSillybattles: {
    id: 'app.components.GamesSection.gamesSillybattles',
    defaultMessage:
      'Controle sua espada e destrua seus inimigos! Ganhe poderes cada vez mais fortes para dominar o campo de batalha.',
  },
  gamesSkyAdventure: {
    id: 'app.components.GamesSection.gamesSkyAdventure',
    defaultMessage:
      'Pilote seu avião, entregue passageiros, melhore suas habilidades e torne-se o rei dos céus!',
  },
  gamesSlotFighters: {
    id: 'app.components.GamesSection.gamesSlotFighters',
    defaultMessage:
      'Puxe a alavanca para combinar suas habilidades de combate e derrotar o oponente. Prove que você é o melhor Slot Fighter!',
  },
  gamesRopeChampion: {
    id: 'app.components.GamesSection.gamesRopeChampion',
    defaultMessage:
      'Salte contra as cordas para se jogar nos inimigos até ser o último em pé no ringue!',
  },
  gamesFlipAndFit: {
    id: 'app.components.GamesSection.gamesFlipAndFit',
    defaultMessage:
      'Vire as peças para preencher a área do objetivo! Resolva quebra-cabeças e revele desenhos neste jogo relaxante.',
  },
  gamesZRoad: {
    id: 'app.components.GamesSection.gamesZRoad',
    defaultMessage:
      'Pise no acelerador e livre-se do máximo de zumbis que puder pelo caminho. Melhore seu carro para transformá-lo em uma máquina enorme!',
  },
  gamesThrowGang: {
    id: 'app.components.GamesSection.gamesThrowGang',
    defaultMessage:
      'Jogue a bolsa de dinheiro e evite ser pego pela polícia! Alcance a van para escapar e obter um grande saque!',
  },
  gamesSlottyDates: {
    id: 'app.components.GamesSection.gamesSlottyDates',
    defaultMessage:
      'Puxe a alavanca e tente impressionar seu par! Cuidado com os gostos e desgostos dos seus dates!',
  },
  gamesSillyClimber: {
    id: 'app.components.GamesSection.gamesSillyClimber',
    defaultMessage:
      'Segure a tela para levantar um pé, arraste para movê-lo e solte para dar um passo. Caminhe por caminhos perigosos para chegar ao topo de todas as montanhas!',
  },
  gamesLoopyRoads: {
    id: 'app.components.GamesSection.gamesLoopyRoads',
    defaultMessage: 'Use veículos diferentes para ajudar a cidade, completando tarefas pelas ruas!',
  },
  gamesGearRush: {
    id: 'app.components.GamesSection.gamesGearRush',
    defaultMessage: 'Girando as engrenagens no chão, ajude-os a chegar à linha final!',
  },
  gamesElasticFight: {
    id: 'app.components.GamesSection.gamesElasticFight',
    defaultMessage:
      'Segure a tela para esticar seu punho, coletando power-ups para um soco avassalador! Derrube seu oponente e vença a luta!',
  },
  gamesDashMiner: {
    id: 'app.components.GamesSection.gamesDashMiner',
    defaultMessage:
      'Corra por cavernas, enfrente perigos e escape com o máximo de minerais preciosos que puder!',
  },
  gamesCookieShapes: {
    id: 'app.components.GamesSection.gamesCookieShapes',
    defaultMessage: 'Prepare deliciosas receitas e enfeite os cookies mais bonitos do mercado!',
  },
});
