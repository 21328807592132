import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import messages from './messages';
import { SECTIONS } from '../constants';

const MaskButtonScroll = ({ scrollToSection, scrollPosition }) => {
  const intl = useIntl();
  return (
    <div id="mask-button-scroll" className={scrollPosition <= 50 ? 'active' : 'inactive'}>
      <button
        type="button"
        className="scroll-down-button"
        onClick={() => scrollToSection(SECTIONS.emphasis)}
      >
        {intl.formatMessage(messages.ourPrideScrollButton)}
      </button>
    </div>
  );
};

MaskButtonScroll.propTypes = {
  scrollToSection: PropTypes.func.isRequired,
  scrollPosition: PropTypes.number.isRequired,
};

export default MaskButtonScroll;
