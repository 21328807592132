import React from 'react';
import { useIntl } from 'react-intl';

import { INSTAGRAM_SOCIAL_MEDIA, LINKEDIN_SOCIAL_MEDIA } from '../constants';
import messages from './messages';
import LogoSmall from '../../../assets/icons/logo-small.svg';

const Footer = () => {
  const intl = useIntl();

  return (
    <footer id="footer">
      <div className="footer-social-medias">
        <p>{intl.formatMessage(messages.socialMidia)}</p>
        <button
          type="button"
          aria-label="Go to Instagram!"
          className="logo instagram-logo black"
          onClick={() => window.open(INSTAGRAM_SOCIAL_MEDIA, '_blank')}
        />
        <button
          type="button"
          aria-label="Go to LinkedIn!"
          className="logo linkedin-logo black"
          onClick={() => window.open(LINKEDIN_SOCIAL_MEDIA, '_blank')}
        />
      </div>
      <div className="footer-description">
        <img src={LogoSmall} alt="Logo Hyperdive" />
        <p>{intl.formatMessage(messages.footerText)}</p>
      </div>
      <div className="button-top-section">
        <button
          type="button"
          className="button-to-top"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          {intl.formatMessage(messages.topButton)}
        </button>
      </div>
    </footer>
  );
};

export default Footer;
