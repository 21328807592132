import React, { useState } from "react";
import PropTypes from "prop-types";

const ScreenshotModal = ({ setScreenshotModalState, screenshotModalState }) => {
  const { initialIndex, screenshots } = screenshotModalState;

  const [screenshotIndex, setScreenshotIndex] = useState(initialIndex);

  const closeModal = () =>
    setScreenshotModalState({ open: false, screenshots: [], initialIndex: 0 });

  const nextImg = () =>
    setScreenshotIndex((value) => {
      if (value >= screenshots.length - 1) {
        return 0;
      }

      return value + 1;
    });

  const previousImg = () =>
    setScreenshotIndex((value) => {
      if (value <= 0) {
        return screenshots.length - 1;
      }

      return value - 1;
    });

  return (
    <div id="screenshot-modal" role="dialog">
      <div className="screenshot-modal-content">
        <button
          className="close-modal actions-buttons-next-prev close-icon"
          type="button"
          aria-label="close-dialog"
          onClick={closeModal}
        />
        <div className="screenshot-modal-buttons">
          <button
            className="actions-buttons-next-prev previous-icon"
            type="button"
            aria-label="close-dialog"
            onClick={previousImg}
          />
          <button
            className="actions-buttons-next-prev next-icon"
            type="button"
            aria-label="close-dialog"
            onClick={nextImg}
          />
        </div>
        <div className="screenshot">
          <img src={screenshots[screenshotIndex]} alt="Game screenshot" />
        </div>
      </div>
    </div>
  );
};

ScreenshotModal.propTypes = {
  setScreenshotModalState: PropTypes.func.isRequired,
  screenshotModalState: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    screenshots: PropTypes.array.isRequired,
    initialIndex: PropTypes.number.isRequired,
  }).isRequired,
};

export default ScreenshotModal;
