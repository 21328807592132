import React from "react";
import PropTypes from "prop-types";

const PappersGradeModal = ({ setVideoModalState, videoUrl }) => (
  <div id="game-video-modal" role="dialog">
    <div className="game-video-modal-content">
      <button
        className="close-modal actions-buttons-next-prev close-icon"
        type="button"
        aria-label="close-dialog"
        onClick={() => setVideoModalState({ open: false, videoUrl: null })}
      />
      <div className="game-video">
        <iframe
          width="100%"
          height="100%"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </div>
  </div>
);

PappersGradeModal.propTypes = {
  setVideoModalState: PropTypes.func.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

export default PappersGradeModal;
