import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import projects from './projects';
import messages from './messages';
import { SEE_MORE_URL } from '../constants';

const ProjectsSection = ({ setShowProjectsModal, setCurrentProjectModal }) => {
  const intl = useIntl();

  const seeMoreText = intl.formatMessage(messages.gamesSectionSeeMore);
  const projectsTitle = intl.formatMessage(messages.gamesSectionTitle);
  const projectsDescription = intl.formatMessage(messages.gamesSectionDescription);
  const expandText = intl.formatMessage(messages.gamesSectionExpand);

  return (
    <article id="projects-section">
      <section className="projects-section-container">
        <div className="game-item game-item-square title-box mobile">
          <h2>{projectsTitle}</h2>
          <p>{projectsDescription}</p>
          <button type="button" onClick={() => window.open(SEE_MORE_URL, '_blank')}>
            {seeMoreText}
          </button>
        </div>
        <div className="games-grid">
          <div className="game-item game-item-square title-box desktop">
            <h2>{projectsTitle}</h2>
            <p>{projectsDescription}</p>
            <button type="button" onClick={() => window.open(SEE_MORE_URL, '_blank')}>
              {seeMoreText}
            </button>
          </div>
          {projects.map((project) => (
            <div
              className={`game-item game-item-${project.projectFormat}`}
              key={`project-grid-${project.projectName}`}
            >
              <img
                src={project.projectImages[0]}
                alt={`${project.projectName}`}
                className="game-item-image"
              />
              <div className="game-item-brand">{`${project.projectName}`}</div>
              <button
                className="game-item-hover-mask"
                type="button"
                aria-label="open game details"
                onClick={() => {
                  setCurrentProjectModal(project);
                  setShowProjectsModal(true);
                }}
              >
                <div className="game-item-expand-button" />
                <div className="game-item-expand-label">{expandText}</div>
              </button>
            </div>
          ))}
        </div>
      </section>
    </article>
  );
};

ProjectsSection.propTypes = {
  setShowProjectsModal: PropTypes.func.isRequired,
  setCurrentProjectModal: PropTypes.func.isRequired,
};

export default ProjectsSection;
