export const SECTIONS = {
  projects: 'projects',
  emphasis: 'emphasis',
  essence: 'essence',
  contact: 'contact',
};

export const SECTIONS_TO_ID = {
  projects: 'projects-section',
  emphasis: 'dark-crown-section',
  essence: 'essence-section',
  contact: 'contact-section',
};

export const PAPPERS_GRADE_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=com.hyperdivestudio.papersgradeplease';
export const PAPPERS_GRADE_APP_STORE =
  'https://apps.apple.com/us/app/papers-grade-please/id1511646233';
export const DARK_CROWN_STEAM = 'https://store.steampowered.com/app/3021800/Dark_Crown/';

export const DARK_CROWN_VIDEO_URL = 'https://www.youtube.com/embed/gUtiNDwe3v8';
export const PAPPERS_GRADE_VIDEO_URL = 'https://www.youtube.com/embed/OALyBYeJ6RE'

export const INSTAGRAM_SOCIAL_MEDIA = 'https://www.instagram.com/hyperdivestudio/';
export const LINKEDIN_SOCIAL_MEDIA = 'https://www.linkedin.com/company/hyper-dive/';

export const SEE_MORE_URL = 'https://play.google.com/store/apps/dev?id=5633019344492148961';

export default SECTIONS;
