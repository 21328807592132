import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import messages from "./messages";
import PappersGradeBanner from "../../../assets/img/pappers-grade-banner.png";

import {
  PAPPERS_GRADE_PLAY_STORE,
  PAPPERS_GRADE_APP_STORE,
  PAPPERS_GRADE_VIDEO_URL,
} from "../constants";

const PappersGradeSection = ({ setVideoModalState }) => {
  const intl = useIntl();

  return (
    <article id="pappers-grade-section">
      <div className="overlay" />
      <div className="content">
        <img
          className="pappers-grade-banner"
          src={PappersGradeBanner}
          alt={intl.formatMessage(messages.pappersGradeBanner)}
        />
        <div className="buttons-section">
          <div className="wrapper-play-video-button">
            <button
              type="button"
              className="active play-video-button"
              onClick={() =>
                setVideoModalState({
                  open: true,
                  videoUrl: PAPPERS_GRADE_VIDEO_URL,
                })
              }
            >
              {intl.formatMessage(messages.buttonVideo)}
            </button>
          </div>
          <button
            type="button"
            className="play-store-button"
            aria-label="open pappers grande in play store"
            onClick={() => window.open(PAPPERS_GRADE_PLAY_STORE, "_blank")}
          />
          <button
            type="button"
            className="app-store-button"
            aria-label="open pappers grande in app store"
            onClick={() => window.open(PAPPERS_GRADE_APP_STORE, "_blank")}
          />
        </div>
      </div>
    </article>
  );
};

PappersGradeSection.propTypes = {
  setVideoModalState: PropTypes.func.isRequired,
};

export default PappersGradeSection;
