import { defineMessages } from 'react-intl';

export default defineMessages({
  essenceTitle: {
    id: 'app.components.EssenceSection.essenceTitle',
    defaultMessage: 'NOSSA ESSÊNCIA',
  },
  essenceInvolvementTitle: {
    id: 'app.components.EssenceSection.essenceInvolvementTitle',
    defaultMessage: 'ENVOLVIMENTO',
  },
  essenceInvolvementDescription: {
    id: 'app.components.EssenceSection.essenceInvolvementDescription',
    defaultMessage: 'Se importar com o que está sendo feito e querer fazer o melhor possível.',
  },
  essenceCriativityTitle: {
    id: 'app.components.EssenceSection.essenceCriativityTitle',
    defaultMessage: 'CRIATIVIDADE',
  },
  essenceCriativityDescription: {
    id: 'app.components.EssenceSection.essenceCriativityDescription',
    defaultMessage: 'A habilidade de (re)conjugar algo de uma nova forma.',
  },
  essenceCollaborationTitle: {
    id: 'app.components.EssenceSection.essenceCollaborationTitle',
    defaultMessage: 'COLABORAÇÃO',
  },
  essenceCollaborationDescription: {
    id: 'app.components.EssenceSection.essenceCollaborationDescription',
    defaultMessage: 'Tentar não desenvolver algo sozinho, buscar construir as coisas em conjunto.',
  },
  essenceExperimentationTitle: {
    id: 'app.components.EssenceSection.essenceExperimentationTitle',
    defaultMessage: 'EXPERIMENTAÇÃO',
  },
  essenceExperimentationDescription: {
    id: 'app.components.EssenceSection.essenceExperimentationDescription',
    defaultMessage:
      'Se  basear em dados e experiências muito mais que em opiniões, mas sem medo de arriscar.',
  },
  essenceAgilityTitle: {
    id: 'app.components.EssenceSection.essenceAgilityTitle',
    defaultMessage: 'AGILIDADE',
  },
  essenceAgilityDescription: {
    id: 'app.components.EssenceSection.essenceAgilityDescription',
    defaultMessage:
      'Saber elencar as prioridades. Trabalhar mais no que é mais importante e trabalhar de forma espiral.',
  },
  essenceEmpathyTitle: {
    id: 'app.components.EssenceSection.essenceEmpathyTitle',
    defaultMessage: 'EMPATIA',
  },
  essenceEmpathyDescription: {
    id: 'app.components.EssenceSection.essenceEmpathyDescription',
    defaultMessage:
      'Se colocar no lugar do outro. Promover uma cultura de pertencimento, acolhimento e parceria.',
  },
});
