import { defineMessages } from 'react-intl';

export default defineMessages({
  pappersGradeBanner: {
    id: 'app.components.PappersGradeSection.pappersGradeBanner',
    defaultMessage: 'Já foram realizados mais de 100 milhões de downloads do jogo Pappers Grade Please!',
  },
  buttonVideo: {
    id: 'app.components.PappersGradeSection.buttonVideo',
    defaultMessage: 'Vídeo',
  },
});
