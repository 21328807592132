import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import messages from './messages';

const isInTheMiddleOfScreen = (element, scrollPosition) => {
  if (element) {
    return (
      parseInt(element.offsetTop, 10) + parseInt(element.offsetHeight, 10) / 2 > scrollPosition &&
      parseInt(element.offsetTop, 10) - parseInt(element.offsetHeight, 10) / 2 < scrollPosition
    );
  }

  return false;
};

const EssenceSection = ({ scrollPosition }) => {
  const intl = useIntl();

  const involvementRef = useRef(null);
  const criativityRef = useRef(null);
  const collaborationRef = useRef(null);
  const experimentationRef = useRef(null);
  const agilityRef = useRef(null);
  const empathyRef = useRef(null);

  const scrollRelatedToScreenPosition = scrollPosition + parseInt(window.innerHeight, 10) / 2;

  const activeInvolvement = isInTheMiddleOfScreen(
    involvementRef.current,
    scrollRelatedToScreenPosition,
  );
  const activeCriativity = isInTheMiddleOfScreen(
    criativityRef.current,
    scrollRelatedToScreenPosition,
  );
  const activeCollaboration = isInTheMiddleOfScreen(
    collaborationRef.current,
    scrollRelatedToScreenPosition,
  );
  const activeExperimentation = isInTheMiddleOfScreen(
    experimentationRef.current,
    scrollRelatedToScreenPosition,
  );
  const activeAgility = isInTheMiddleOfScreen(agilityRef.current, scrollRelatedToScreenPosition);
  const activeEmpathy = isInTheMiddleOfScreen(empathyRef.current, scrollRelatedToScreenPosition);

  return (
    <article id="essence-section">
      <div className="top-wave" />
      <div className="essence-section-content">
        <h1>{intl.formatMessage(messages.essenceTitle)}</h1>
        <div className="essence-items">
          <div className={`essence-item ${activeInvolvement && 'active'}`} ref={involvementRef}>
            <div className="essence-icon involvement" />
            <div className="essence-item-label">
              {intl.formatMessage(messages.essenceInvolvementTitle)}
            </div>
            <div className="essence-item-description">
              {intl.formatMessage(messages.essenceInvolvementDescription)}
            </div>
          </div>
          <div className={`essence-item ${activeCriativity && 'active'}`} ref={criativityRef}>
            <div className="essence-icon criativity" />
            <div className="essence-item-label">
              {intl.formatMessage(messages.essenceCriativityTitle)}
            </div>
            <div className="essence-item-description">
              {intl.formatMessage(messages.essenceCriativityDescription)}
            </div>
          </div>
          <div className={`essence-item ${activeCollaboration && 'active'}`} ref={collaborationRef}>
            <div className="essence-icon collaboration" />
            <div className="essence-item-label">
              {intl.formatMessage(messages.essenceCollaborationTitle)}
            </div>
            <div className="essence-item-description">
              {intl.formatMessage(messages.essenceCollaborationDescription)}
            </div>
          </div>
          <div
            className={`essence-item ${activeExperimentation && 'active'}`}
            ref={experimentationRef}
          >
            <div className="essence-icon experimentation" />
            <div className="essence-item-label">
              {intl.formatMessage(messages.essenceExperimentationTitle)}
            </div>
            <div className="essence-item-description">
              {intl.formatMessage(messages.essenceExperimentationDescription)}
            </div>
          </div>
          <div className={`essence-item ${activeAgility && 'active'}`} ref={agilityRef}>
            <div className="essence-icon agility" />
            <div className="essence-item-label">
              {intl.formatMessage(messages.essenceAgilityTitle)}
            </div>
            <div className="essence-item-description">
              {intl.formatMessage(messages.essenceAgilityDescription)}
            </div>
          </div>
          <div className={`essence-item ${activeEmpathy && 'active'}`} ref={empathyRef}>
            <div className="essence-icon empathy" />
            <div className="essence-item-label">
              {intl.formatMessage(messages.essenceEmpathyTitle)}
            </div>
            <div className="essence-item-description">
              {intl.formatMessage(messages.essenceEmpathyDescription)}
            </div>
          </div>
        </div>
      </div>
      <div className="top-wave inverse" />
    </article>
  );
};

EssenceSection.propTypes = {
  scrollPosition: PropTypes.number.isRequired,
};

export default EssenceSection;
