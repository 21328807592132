import { defineMessages } from 'react-intl';

export default defineMessages({
  navItemEmphasis: {
    id: 'app.components.AppBar.navItemEmphasis',
    defaultMessage: 'Destaque',
  },
  navItemProjects: {
    id: 'app.components.AppBar.navItemProjects',
    defaultMessage: 'Projetos',
  },
  navItemEssence: {
    id: 'app.components.AppBar.navItemEssence',
    defaultMessage: 'Essência',
  },
  navItemContact: {
    id: 'app.components.AppBar.navItemContact',
    defaultMessage: 'Contato',
  },
});
