import sillyBattlesImg1 from '../../../assets/img/games/01_SillyBattles/01_GRID_SillyBattles.png';
import sillyBattlesImg2 from '../../../assets/img/games/01_SillyBattles/02_SillyBattles.png';
import sillyBattlesImg3 from '../../../assets/img/games/01_SillyBattles/03_SillyBattles.png';
import sillyBattlesImg4 from '../../../assets/img/games/01_SillyBattles/04_SillyBattles.png';

import skyAdventureImg1 from '../../../assets/img/games/02_Sky_Adventure/01_GRID_SkyAdventure.png';
import skyAdventureImg2 from '../../../assets/img/games/02_Sky_Adventure/02_SkyAdventure.png';
import skyAdventureImg3 from '../../../assets/img/games/02_Sky_Adventure/03_SkyAdventure.png';
import skyAdventureImg4 from '../../../assets/img/games/02_Sky_Adventure/04_SkyAdventure.png';

import ThrowGangImg1 from '../../../assets/img/games/03_Throw_Gang/01_GRID_ThrowGang.png';
import ThrowGangImg2 from '../../../assets/img/games/03_Throw_Gang/02_ThrowGang.png';
import ThrowGangImg3 from '../../../assets/img/games/03_Throw_Gang/03_ThrowGang.png';
import ThrowGangImg4 from '../../../assets/img/games/03_Throw_Gang/04_ThrowGang.png';

import RopeChampionImg1 from '../../../assets/img/games/04_RopeChampion/01_GRID_RopeChampion.png';
import RopeChampionImg2 from '../../../assets/img/games/04_RopeChampion/02_RopeChampion.png';
import RopeChampionImg3 from '../../../assets/img/games/04_RopeChampion/03_RopeChampion.png';
import RopeChampionImg4 from '../../../assets/img/games/04_RopeChampion/04_RopeChampion.png';

import GearRushImg1 from '../../../assets/img/games/05_Gear_Rush/01_GRID_GearRush.png';
import GearRushImg2 from '../../../assets/img/games/05_Gear_Rush/02_GearRush.png';
import GearRushImg3 from '../../../assets/img/games/05_Gear_Rush/03_GearRush.png';
import GearRushImg4 from '../../../assets/img/games/05_Gear_Rush/04_GearRush.png';

import LoopyRoadsImg1 from '../../../assets/img/games/06_Loopy_Roads/01_GRID_LoopyRoads.png';
import LoopyRoadsImg2 from '../../../assets/img/games/06_Loopy_Roads/02_LoopyRoads.png';
import LoopyRoadsImg3 from '../../../assets/img/games/06_Loopy_Roads/03_LoopyRoads.png';
import LoopyRoadsImg4 from '../../../assets/img/games/06_Loopy_Roads/04_LoopyRoads.png';

import FlipAndFitImg1 from '../../../assets/img/games/07_FlipAndFit/01_GRID_Flip_Fit.png';
import FlipAndFitImg2 from '../../../assets/img/games/07_FlipAndFit/02_Flip_Fit.png';
import FlipAndFitImg3 from '../../../assets/img/games/07_FlipAndFit/03_Flip_Fit.png';
import FlipAndFitImg4 from '../../../assets/img/games/07_FlipAndFit/04_Flip_Fit.png';

import ZRoadImg1 from '../../../assets/img/games/08_Zroad/01_GRID_ZRoad.png';
import ZRoadImg2 from '../../../assets/img/games/08_Zroad/02_ZRoad.png';
import ZRoadImg3 from '../../../assets/img/games/08_Zroad/03_ZRoad.png';
import ZRoadImg4 from '../../../assets/img/games/08_Zroad/04_ZRoad.png';

import SlottyDatesImg1 from '../../../assets/img/games/09_Slotty Dates/01_GRID_SlottyDates.png';
import SlottyDatesImg2 from '../../../assets/img/games/09_Slotty Dates/02_SlottyDates.png';
import SlottyDatesImg3 from '../../../assets/img/games/09_Slotty Dates/03_SlottyDates.png';
import SlottyDatesImg4 from '../../../assets/img/games/09_Slotty Dates/04_SlottyDates.png';

import DashMinerImg1 from '../../../assets/img/games/10_Dash Miner/01_GRID_DashMiner.png';
import DashMinerImg2 from '../../../assets/img/games/10_Dash Miner/02_DashMiner.png';
import DashMinerImg3 from '../../../assets/img/games/10_Dash Miner/03_DashMiner.png';
import DashMinerImg4 from '../../../assets/img/games/10_Dash Miner/04_DashMiner.png';

import ElasticFightImg1 from '../../../assets/img/games/11_Elastic Fight/01_GRID_ElasticFight.png';
import ElasticFightImg2 from '../../../assets/img/games/11_Elastic Fight/02_ElasticFight.png';
import ElasticFightImg3 from '../../../assets/img/games/11_Elastic Fight/03_ElasticFight.png';
import ElasticFightImg4 from '../../../assets/img/games/11_Elastic Fight/04_ElasticFight.png';

import SlotFightersImg1 from '../../../assets/img/games/12_Slot Fighters/01_GRID_SlotFighters.png';
import SlotFightersImg2 from '../../../assets/img/games/12_Slot Fighters/02_SlotFighters.png';
import SlotFightersImg3 from '../../../assets/img/games/12_Slot Fighters/03_SlotFighters.png';
import SlotFightersImg4 from '../../../assets/img/games/12_Slot Fighters/04_SlotFighters.png';

import SillyClimberImg1 from '../../../assets/img/games/13_Silly Climber/01_GRID_SillyClimber.png';
import SillyClimberImg2 from '../../../assets/img/games/13_Silly Climber/02_SillyClimber.png';
import SillyClimberImg3 from '../../../assets/img/games/13_Silly Climber/03_SillyClimber.png';
import SillyClimberImg4 from '../../../assets/img/games/13_Silly Climber/04_SillyClimber.png';

import CookieShapesImg1 from '../../../assets/img/games/14_Cookie Shapes/GRID_01_CookieShapes.png';
import CookieShapesImg2 from '../../../assets/img/games/14_Cookie Shapes/02_CookieShapes.png';
import CookieShapesImg3 from '../../../assets/img/games/14_Cookie Shapes/03_CookieShapes.png';
import CookieShapesImg4 from '../../../assets/img/games/14_Cookie Shapes/04_CookieShapes.png';

import messages from './messages';

const projects = [
  {
    projectName: 'Silly Battles',
    projectFormat: 'square',
    projectImages: [sillyBattlesImg1, sillyBattlesImg2, sillyBattlesImg3, sillyBattlesImg4],
    projectDescription: messages.gamesSillybattles,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.sillybattles',
  },
  {
    projectName: 'Sky Adventure',
    projectFormat: 'rectangle',
    projectImages: [skyAdventureImg1, skyAdventureImg2, skyAdventureImg3, skyAdventureImg4],
    projectDescription: messages.gamesSkyAdventure,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.skyadventure',
  },
  {
    projectName: 'Slot Fighters',
    projectFormat: 'square',
    projectImages: [SlotFightersImg1, SlotFightersImg2, SlotFightersImg3, SlotFightersImg4],
    projectDescription: messages.gamesSlotFighters,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.slotfighters',
  },
  {
    projectName: 'Rope Champion',
    projectFormat: 'rectangle',
    projectImages: [RopeChampionImg1, RopeChampionImg2, RopeChampionImg3, RopeChampionImg4],
    projectDescription: messages.gamesRopeChampion,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.ropechampion2',
  },
  {
    projectName: 'Gear Rush!',
    projectFormat: 'square',
    projectImages: [GearRushImg1, GearRushImg2, GearRushImg3, GearRushImg4],
    projectDescription: messages.gamesGearRush,
    projectPlayStore: 'https://play.google.com/store/apps/details?id=com.hyperdivestudio.gearrush',
  },
  {
    projectName: 'Loopy Roads',
    projectFormat: 'square',
    projectImages: [LoopyRoadsImg1, LoopyRoadsImg2, LoopyRoadsImg3, LoopyRoadsImg4],
    projectDescription: messages.gamesLoopyRoads,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.loopyroads',
  },
  {
    projectName: 'Flip & Fit',
    projectFormat: 'square',
    projectImages: [FlipAndFitImg1, FlipAndFitImg2, FlipAndFitImg3, FlipAndFitImg4],
    projectDescription: messages.gamesFlipAndFit,
    projectPlayStore: 'https://play.google.com/store/apps/details?id=com.hyperdivestudio.flipnfit',
  },
  {
    projectName: 'ZRoad',
    projectFormat: 'rectangle',
    projectImages: [ZRoadImg1, ZRoadImg2, ZRoadImg3, ZRoadImg4],
    projectDescription: messages.gamesZRoad,
    projectPlayStore: 'https://play.google.com/store/apps/details?id=com.hyperdivestudio.zroad',
  },
  {
    projectName: 'Slotty Dates',
    projectFormat: 'square',
    projectImages: [SlottyDatesImg1, SlottyDatesImg2, SlottyDatesImg3, SlottyDatesImg4],
    projectDescription: messages.gamesSlottyDates,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.slottydates',
  },
  {
    projectName: 'Dash Miner',
    projectFormat: 'rectangle',
    projectImages: [DashMinerImg1, DashMinerImg2, DashMinerImg3, DashMinerImg4],
    projectDescription: messages.gamesDashMiner,
    projectPlayStore: 'https://play.google.com/store/apps/details?id=com.hyperdivestudio.dashminer',
  },
  {
    projectName: 'Elastic Fight',
    projectFormat: 'square',
    projectImages: [ElasticFightImg1, ElasticFightImg2, ElasticFightImg3, ElasticFightImg4],
    projectDescription: messages.gamesElasticFight,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.elasticfight',
  },
  {
    projectName: 'Throw Gang',
    projectFormat: 'rectangle',
    projectImages: [ThrowGangImg1, ThrowGangImg2, ThrowGangImg3, ThrowGangImg4],
    projectDescription: messages.gamesThrowGang,
    projectPlayStore: 'https://play.google.com/store/apps/details?id=com.hyperdivestudio.throwgang',
  },
  {
    projectName: 'Silly Climber',
    projectFormat: 'square',
    projectImages: [SillyClimberImg1, SillyClimberImg2, SillyClimberImg3, SillyClimberImg4],
    projectDescription: messages.gamesSillyClimber,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.silly_climber_3d',
  },
  {
    projectName: 'Cookie Shapes',
    projectFormat: 'square',
    projectImages: [CookieShapesImg1, CookieShapesImg2, CookieShapesImg3, CookieShapesImg4],
    projectDescription: messages.gamesCookieShapes,
    projectPlayStore:
      'https://play.google.com/store/apps/details?id=com.hyperdivestudio.sillybattles',
  },
];

export default projects;
