import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import messages from './messages';

import Carousel from '../Carousel/Carousel';

import CarouselImg1 from '../../../assets/img/carousel/escritorio_01.png';
import CarouselImg2 from '../../../assets/img/carousel/escritorio_02.png';
import CarouselImg3 from '../../../assets/img/carousel/escritorio_03.png';
import CarouselImg4 from '../../../assets/img/carousel/escritorio_04.png';
import CarouselImg5 from '../../../assets/img/carousel/escritorio_05.png';

const ContactSection = () => {
  const intl = useIntl();
  const [copyEmailState, setCopyEmailState] = useState(messages.emailCopyAction);
  const [copyPhoneState, setCopyPhoneState] = useState(messages.phoneCopyAction);
  const [copyAddressState, setCopyAddressState] = useState(messages.addressCopyAction);

  return (
    <article id="contact-section">
      <div id="carousel">
        <Carousel
          imgs={[CarouselImg1, CarouselImg2, CarouselImg3, CarouselImg4, CarouselImg5]}
          title={intl.formatMessage(messages.carouselTitle)}
        />
      </div>
      <div className="contact-session">
        <div className="contact-session-title">
          <h1>{intl.formatMessage(messages.pageTitle)}</h1>
        </div>
        <CopyToClipboard
          text="contato@hyperdivestudio.com"
          onCopy={() => setCopyEmailState(messages.emailCopied)}
        >
          <div
            className="contact-item"
            onMouseLeave={() => setCopyEmailState(messages.emailCopyAction)}
          >
            <div className="non-hover-text">
              <div className="email-icon" />
              <p>contato@hyperdivestudio.com</p>
            </div>
            <div className="hover-text">
              <div className="copy-icon" />
              <p>{intl.formatMessage(copyEmailState)}</p>
            </div>
          </div>
        </CopyToClipboard>
        <CopyToClipboard
          text="+55 51 996694247"
          onCopy={() => setCopyPhoneState(messages.phoneCopied)}
        >
          <div
            className="contact-item"
            onMouseLeave={() => setCopyPhoneState(messages.phoneCopyAction)}
          >
            <div className="non-hover-text">
              <div className="phone-number-icon" />
              <p>+55 51 996694247</p>
            </div>
            <div className="hover-text">
              <div className="copy-icon" />
              <p>{intl.formatMessage(copyPhoneState)}</p>
            </div>
          </div>
        </CopyToClipboard>
        <CopyToClipboard
          text="Av. Ipiranga, 40 - sala 602 Praia de Belas, Porto Alegre/RS 90160-090"
          onCopy={() => setCopyAddressState(messages.addressCopied)}
        >
          <div
            className="contact-item"
            onMouseLeave={() => setCopyAddressState(messages.addressCopyAction)}
          >
            <div className="non-hover-text">
              <div className="address-icon" />
              <p>Av. Ipiranga, 40 - sala 602 Praia de Belas, Porto Alegre/RS 90160-090</p>
            </div>
            <div className="hover-text">
              <div className="copy-icon" />
              <p>{intl.formatMessage(copyAddressState)}</p>
            </div>
          </div>
        </CopyToClipboard>
      </div>
      <div className="contact-section-map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.6446164898075!2d-51.230199899999995!3d-30.0470519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951978fb68a2270d%3A0xf2ac3125c0e0bac6!2sAv.%20Ipiranga%2C%2040%20-%20sala%20602%20-%20Praia%20de%20Belas%2C%20Porto%20Alegre%20-%20RS%2C%2090160-090!5e0!3m2!1spt-BR!2sbr!4v1687401069767!5m2!1spt-BR!2sbr"
          width="100%"
          height="100%"
          style={{ border: '0px' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </article>
  );
};

export default ContactSection;
