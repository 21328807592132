import React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { useIntl } from "react-intl";

import messages from "./messages";
import darCrownLogo from "../../../assets/img/dark-crown-logo.png";
import screentShot1 from "../../../assets/img/dark-crown/screenshot_01.png";
import screentShot2 from "../../../assets/img/dark-crown/screenshot_02.png";
import screentShot3 from "../../../assets/img/dark-crown/screenshot_03.png";
import screentShot4 from "../../../assets/img/dark-crown/screenshot_04.png";
import screentShot5 from "../../../assets/img/dark-crown/screenshot_05.png";
import screentShot6 from "../../../assets/img/dark-crown/screenshot_06.png";
import whiteListIcon from "../../../assets/icons/wishlist_button.png";
import { DARK_CROWN_STEAM, DARK_CROWN_VIDEO_URL } from "../constants";

const DarkCrownSection = ({ setVideoModalState, setScreenshotModalState }) => {
  const intl = useIntl();

  const screenshots = [
    screentShot1,
    screentShot2,
    screentShot3,
    screentShot4,
    screentShot5,
    screentShot6,
  ];

  return (
    <article id="dark-crown-section">
      <div id="dark-crown-banner">
        <div className="dark-crown-logo-wrapper">
          <img
            src={darCrownLogo}
            alt="DarkCrown logo"
            className="dark-crown-logo"
          />
        </div>
        <div className="buttons-section">
          <button
            type="button"
            className="active play-video-button"
            onClick={() =>
              setVideoModalState({
                open: true,
                videoUrl: DARK_CROWN_VIDEO_URL,
              })
            }
          >
            {intl.formatMessage(messages.buttonVideo)}
          </button>
        </div>
        <div className="dark-crown-text">
          <p>{intl.formatMessage(messages.darkCrownDescription)}</p>
        </div>
      </div>
      <div id="dark-crown-screenshots">
        <div className="separator top-separator" />
        <div className="screenshots-grid">
          {screenshots.map((screenshot, index) => (
            <div className="screenshots-item" key={v4()}>
              <img src={screenshot} alt="Dark Crowd Screenshot" />
              <button
                className="screenshot-hover-mask"
                type="button"
                aria-label="open game details"
                onClick={() =>
                  setScreenshotModalState({
                    open: true,
                    initialIndex: index,
                    screenshots,
                  })
                }
              >
                <div className="screenshot-expand-button" />
                <div className="screenshot-expand-label">
                  {intl.formatMessage(messages.expandText)}
                </div>
              </button>
            </div>
          ))}
        </div>
        <div className="separator bottom-separator" />
        <a
          href={DARK_CROWN_STEAM}
          aria-label="Access dark crown on steam."
          className="wishlist-button"
          type="button"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whiteListIcon} alt="Steam icon" />
        </a>
      </div>
    </article>
  );
};

DarkCrownSection.propTypes = {
  setVideoModalState: PropTypes.func.isRequired,
  setScreenshotModalState: PropTypes.func.isRequired,
};

export default DarkCrownSection;
