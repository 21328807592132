import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import '../../../assets/css/carousel.css';

const Carousel = ({ imgs, title }) => {
  const carouselRef = useRef(null);
  const carouselLength = imgs.length;
  const [activeIndex, setActiveIndex] = useState(0);

  const nextState = () => {
    setActiveIndex((state) => state + 1);
  };

  const previousState = () => {
    setActiveIndex((state) => state - 1);
  };

  useEffect(() => {
    const timer = setInterval(nextState, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="carousel" ref={carouselRef}>
      {imgs.map((img, index) => (
        <img
          // eslint-disable-next-line react/no-array-index-key
          key={`carousel-item-${index}`}
          className={Math.abs(activeIndex) % carouselLength === index ? 'active' : ''}
          src={img}
          alt="Carousel item"
        />
      ))}
      <div className="carousel-buttons">
        <button
          type="button"
          aria-label="Previous Slide"
          className="actions-buttons-next-prev previous-icon"
          onClick={previousState}
        />
        <button
          type="button"
          aria-label="Next Slide"
          className="actions-buttons-next-prev next-icon"
          onClick={nextState}
        />
      </div>
      <div className="carousel-pages">
        {imgs.map((img, index) => (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={`carousel-button-${index}`}
            type="button"
            className={`carousel-page ${
              Math.abs(activeIndex) % carouselLength === index ? 'active' : ''
            }`}
            onClick={() => setActiveIndex(index)}
            aria-label={`Go to image ${index}`}
          />
        ))}
      </div>
      <div className="carousel-title">{title}</div>
    </div>
  );
};

Carousel.propTypes = {
  imgs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default Carousel;
