import { defineMessages } from 'react-intl';

export default defineMessages({
  socialMidia: {
    id: 'app.components.footer.socialMidia',
    defaultMessage: 'Redes',
  },
  footerText: {
    id: 'app.components.footer.footerText',
    defaultMessage: '2023 © Hyper Dive Game Studio. Todos os direitos reservados.',
  },
  topButton: {
    id: 'app.components.footer.topButton',
    defaultMessage: 'Topo',
  },
});
