import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ProjectsModal = ({ currentProjectModal, setShowProjectsModal }) => {
  const intl = useIntl();
  const [currentImage, setCurrentImage] = useState(0);
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const isMobileView = windowSize[0] < 875;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const modalDetails = (
    <div className="projects-modal-details">
      <h1 className="projects-modal-title">{currentProjectModal.projectName}</h1>
      <p className="projects-modal-description">
        {intl.formatMessage(currentProjectModal.projectDescription)}
      </p>
      <button
        className="projects-modal-play-store play-store-button colored"
        type="button"
        aria-label="open game in play store"
        onClick={() => window.open(currentProjectModal.projectPlayStore, '_blank')}
      />
    </div>
  );

  const itemsArray = currentProjectModal.projectImages.map((image) => (
    <img src={image} alt={`${currentProjectModal.projectName} - ${currentImage}`} />
  ));

  const itemsToBeShown = isMobileView
    ? [...itemsArray.slice(0, 1), modalDetails, ...itemsArray.slice(1)]
    : itemsArray;

  const imagesLength = itemsToBeShown.length;

  const nextImage = () => {
    if (currentImage < imagesLength - 1) {
      setCurrentImage((state) => state + 1);
    }
  };

  const previousImage = () => {
    if (currentImage > 0) {
      setCurrentImage((state) => state - 1);
    }
  };

  return (
    <div role="dialog" id="projects-modal">
      <div className="projects-modal-dialog">
        <button
          className="close-modal actions-buttons-next-prev close-icon"
          type="button"
          aria-label="close-dialog"
          onClick={() => setShowProjectsModal(false)}
        />
        <div className="projects-modal-content">
          <div className="projects-modal-images-section">
            <div className="projects-modal-images">
              {itemsToBeShown.map((item, index) => (
                <div
                  className={`projects-modal-item ${
                    index === currentImage ? 'active-item' : 'inactive-item'
                  }`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`projects-modal-item-${index}`}
                >
                  {item}
                </div>
              ))}
            </div>
            <div className="projects-modal-images-indicator">
              {`${currentImage + 1}/${imagesLength}`}
            </div>
            <div className="projects-modal-images-button">
              <button
                type="button"
                className="actions-buttons-next-prev previous-icon"
                aria-label="previous"
                onClick={previousImage}
              />
              <button
                type="button"
                className="actions-buttons-next-prev next-icon"
                aria-label="next"
                onClick={nextImage}
              />
            </div>
          </div>
          {!isMobileView && modalDetails}
        </div>
      </div>
    </div>
  );
};

ProjectsModal.propTypes = {
  currentProjectModal: PropTypes.shape({
    projectName: PropTypes.string,
    projectFormat: PropTypes.string,
    projectImages: PropTypes.array,
    projectDescription: PropTypes.object,
    projectPlayStore: PropTypes.string,
  }).isRequired,
  setShowProjectsModal: PropTypes.func.isRequired,
};

export default ProjectsModal;
