import React from 'react';
import PropTypes from 'prop-types';

const LanguageSection = ({ language, setLanguage }) => (
  <div className="language-section">
    <div className="language-change-button">
      <button
        type="button"
        {...(language === 'pt' && { className: 'active' })}
        onClick={() => setLanguage('pt')}
      >
        PT
      </button>
      <button
        type="button"
        {...(language === 'en' && { className: 'active' })}
        onClick={() => setLanguage('en')}
      >
        EN
      </button>
    </div>
  </div>
);

LanguageSection.propTypes = {
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default LanguageSection;
