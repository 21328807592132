import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import AppBar from "./components/AppBar/AppBar";
import NavBarFullScreen from "./components/NavBarFullScreen/NavBarFullScreen";
import OurPrideSection from "./components/OurPrideSection/OurPrideSection";
import DarkCrownSection from "./components/DarkCrownSection";
import PappersGradeSection from "./components/PappersGradeSection/PappersGradeSection";
import GameVideoModal from "./components/GameVideoModal";

import ProjectsSection from "./components/ProjectsSection/ProjectsSection";
import ProjectsModal from "./components/ProjectsModal/ProjectsModal";

import EssenceSection from "./components/EssenceSection/EssenceSection";
import ContactSection from "./components/ContactSection/ContactSection";
import Footer from "./components/Footer/Footer";

import MaskButtonScroll from "./components/OurPrideSection/MaskButtonScroll";
import { SECTIONS_TO_ID } from "./components/constants";

import enMessages from "../lang/en-US.json";
import ptMessages from "../lang/pt-BR.json";
import ScreenshotModal from "./components/ScreenshotModal";

const getMessages = (language) => {
  switch (language) {
    case "pt":
      return ptMessages;
    case "en":
      return enMessages;
    default:
      return ptMessages;
  }
};

const changeUrl = (section = null, languange = null) => {
  const splittedUrl = window.location.href.split(/[#?]/);
  const sect = section || splittedUrl[1];
  const lang = languange || splittedUrl[2]?.split("=")[1];

  let path = "";

  if (sect) {
    path += `#${sect}`;
  }

  if (lang) {
    path += `?lang=${lang}`;
  }

  window.history.pushState(section, "", path);
};

const scrollToElementPosition = (section, toggleMenu) => {
  const element = document.getElementById(SECTIONS_TO_ID[section]);

  if (element) {
    const elementTopPosition = element.offsetTop;
    const scrollPosition = toggleMenu
      ? elementTopPosition + 72
      : elementTopPosition;

    changeUrl(section);
    window.scrollTo({ top: scrollPosition, behavior: "smooth" });
  }
};

const App = () => {
  const [language, setLanguage] = useState("pt");
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showProjectsModal, setShowProjectsModal] = useState(false);
  const [videoModalState, setVideoModalState] = useState({
    open: false,
    videoUrl: null,
  });
  const [currentProjectModal, setCurrentProjectModal] = useState({});
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [screenshotModalState, setScreenshotModalState] = useState({
    open: false,
    screenshots: [],
    initialIndex: 0,
  });

  const messages = getMessages(language);

  const scrollToSection = (section) =>
    scrollToElementPosition(section, toggleMenu);

  const changeLanguage = (lang) => {
    changeUrl(null, lang);
    setLanguage(lang);
  };

  useEffect(() => {
    const splittedUrl = window.location.href.split(/[#?]/);
    const section = splittedUrl[1];
    const lang = splittedUrl[2]?.split("=")[1];

    if (section && SECTIONS_TO_ID[section]) {
      scrollToSection(section);
    }

    if (lang === "pt" || lang === "en") {
      setLanguage(lang);
    }

    const handleScrollChange = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScrollChange);

    return () => window.removeEventListener("scroll", handleScrollChange);
  }, []);

  return (
    <IntlProvider locale={language} messages={messages}>
      <AppBar
        language={language}
        setLanguage={changeLanguage}
        scrollToSection={scrollToSection}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && (
        <NavBarFullScreen
          language={language}
          setLanguage={changeLanguage}
          scrollToSection={scrollToSection}
          setToggleMenu={setToggleMenu}
        />
      )}
      {showProjectsModal && (
        <ProjectsModal
          showProjectsModal={showProjectsModal}
          currentProjectModal={currentProjectModal}
          setShowProjectsModal={setShowProjectsModal}
        />
      )}
      {videoModalState.open && (
        <GameVideoModal
          videoUrl={videoModalState.videoUrl}
          setVideoModalState={setVideoModalState}
        />
      )}
      {screenshotModalState.open && (
        <ScreenshotModal
          setScreenshotModalState={setScreenshotModalState}
          screenshotModalState={screenshotModalState}
        />
      )}
      <MaskButtonScroll
        scrollToSection={scrollToSection}
        scrollPosition={scrollPosition}
      />
      <main>
        <OurPrideSection scrollToSection={scrollToSection} />
        <DarkCrownSection
          setVideoModalState={setVideoModalState}
          setScreenshotModalState={setScreenshotModalState}
        />
        <PappersGradeSection setVideoModalState={setVideoModalState} />
        <ProjectsSection
          setShowProjectsModal={setShowProjectsModal}
          setCurrentProjectModal={setCurrentProjectModal}
        />
        <EssenceSection scrollPosition={scrollPosition} />
        <ContactSection />
      </main>
      <Footer />
    </IntlProvider>
  );
};

export default App;
