import { defineMessages } from 'react-intl';

export default defineMessages({
  navBarLogo: {
    id: 'app.components.AppBar.navBarLogo',
    defaultMessage: 'Hyperdive Logo',
  },
  navItemEmphasis: {
    id: 'app.components.AppBar.navItemEmphasis',
    defaultMessage: 'Destaque',
  },
  navItemProjects: {
    id: 'app.components.AppBar.navItemProjects',
    defaultMessage: 'Projetos',
  },
  navItemEssence: {
    id: 'app.components.AppBar.navItemEssence',
    defaultMessage: 'Essência',
  },
  navItemContact: {
    id: 'app.components.AppBar.navItemContact',
    defaultMessage: 'Contato',
  },
  hiringAdvertisement: {
    id: 'app.components.AppBar.hiringAdvertisement',
    defaultMessage: 'Estamos contratando!',
  },
  openMenu: {
    id: 'app.components.AppBar.openMenu',
    defaultMessage: 'Menu',
  },
  closeMenu: {
    id: 'app.components.AppBar.closeMenu',
    defaultMessage: 'Fechar',
  },
});
