import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'app.components.ContactSection.pageTitle',
    defaultMessage: 'Contato',
  },
  carouselTitle: {
    id: 'app.components.ContactSection.carouselTitle',
    defaultMessage: 'Estúdio',
  },
  addressCopyAction: {
    id: 'app.components.ContactSection.addressCopyAction',
    defaultMessage: 'Copiar endereço',
  },
  addressCopied: {
    id: 'app.components.ContactSection.addressCopied',
    defaultMessage: 'Endereço copiado!',
  },
  phoneCopyAction: {
    id: 'app.components.ContactSection.phoneCopyAction',
    defaultMessage: 'Copiar telefone',
  },
  phoneCopied: {
    id: 'app.components.ContactSection.phoneCopied',
    defaultMessage: 'Telefone copiado!',
  },
  emailCopyAction: {
    id: 'app.components.ContactSection.emailCopyAction',
    defaultMessage: 'Copiar E-mail',
  },
  emailCopied: {
    id: 'app.components.ContactSection.emailCopied',
    defaultMessage: 'E-mail copiado!',
  },
});
