import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import messages from './messages';
import ourPrideImg from '../../../assets/img/our-pride-page-logo.svg';
import { SECTIONS } from '../constants';

const OurPrideSection = ({ scrollToSection }) => {
  const intl = useIntl();

  return (
    <article id="our-pride-section">
      <div className="our-pride-page-logo">
        <img src={ourPrideImg} alt="Hyperdive logo" />
      </div>
      <div className="our-pride-page-content">
        <div className="page-content-text">
          <h1>{intl.formatMessage(messages.ourPrideTitle)}</h1>
          <p>{intl.formatMessage(messages.ourPrideDescription)}</p>
        </div>
        <button
          type="button"
          className="scroll-down-button"
          onClick={() => scrollToSection(SECTIONS.emphasis)}
        >
          {intl.formatMessage(messages.ourPrideScrollButton)}
        </button>
      </div>
    </article>
  );
};

OurPrideSection.propTypes = {
  scrollToSection: PropTypes.func.isRequired,
};

export default OurPrideSection;
