import { defineMessages } from 'react-intl';

export default defineMessages({
  ourPrideTitle: {
    id: 'app.components.OurPrideSection.ourPrideTitle',
    defaultMessage: 'Nos divertimos fazendo jogos!',
  },
  ourPrideDescription: {
    id: 'app.components.OurPrideSection.ourPrideDescription',
    defaultMessage:
      ' Temos mais de 6 anos de experiência de desenvolvimento de jogos. Trabalhamos com PC, web e nos últimos anos estivemos focados no mercado mobile com jogos hiper-casuais. Hoje estamos nos preparando para mergulhar em projetos mais ambiciosos.',
  },
  ourPrideScrollButton: {
    id: 'app.components.OurPrideSection.ourPrideScrollButton',
    defaultMessage: 'role para baixo',
  },
});
